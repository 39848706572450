import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin-service';

@Component({
  selector: 'pla-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent implements OnInit {
  public loaded: boolean = false;
  public selectedTab: 'blog' | 'blog-config' | 'gpt' | 'wordbank' | 'static-media' | 'viewer-config' | 'recently-added' = 'blog';

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Check if the user is an admin
    this.adminService.verify().subscribe((data: { admin: boolean }) => {
      if (!data.admin) {
        this.router.navigate(['/landing']);
        return;
      }

      this.loaded = data.admin;

      // Get the 'tab' query parameter and set the selectedTab accordingly
      this.route.queryParams.subscribe(params => {
        const tabParam = params['tab'];
        if (tabParam && ['blog', 'blog-config', 'gpt', 'wordbank', 'static-media', 'viewer-config', 'recently-added'].includes(tabParam)) {
          this.selectedTab = tabParam;
        }
      });
    });
  }

  // Set the selected tab and update the query parameter in the URL
  public SetTab(tab: 'blog' | 'blog-config' | 'gpt' | 'wordbank' | 'static-media' | 'viewer-config' | 'recently-added'): void {
    this.selectedTab = tab;
    this.router.navigate(['/account/admin'], {
      queryParams: { tab: tab },
    });
  }
}
