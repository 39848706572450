import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { StickyConfig, StickyLinkGroup, StickyLink, DEFAULT_STICKY_CONFIG, StickyLinkType, ConfigMeta } from '../sticky-tabs/sticky-tabs.component';

@Component({
  selector: 'pla-main-nav-chips',
  templateUrl: './main-nav-chips.component.html',
  styleUrls: ['./main-nav-chips.component.scss']
})
export class MainNavChipsComponent implements OnInit {

  @Input() public backLink: string | undefined;
  @Input() public useSpacing: boolean = true;
  @Input() public rightPadding: boolean = true;
  @Input() public defaultConfig: boolean = true;
  @Input() public config: ConfigMeta = {
    ...DEFAULT_STICKY_CONFIG,
    sticky: false,
    backgroundColour: 'rgba(13,13,13,0.23)',
    defaultStyle: {
      fontWeight: 'fw-light',
      borderWidth: 1,
      borderColour: '#fff',
      textColour: '#fff',
      backgroundColour: 'transparent'
    },
    selectedStyle: {
      fontWeight: 'fw-light',
      borderWidth: 1,
      borderColour: '#fff',
      textColour: '#2f2f2f',
      backgroundColour: '#fff'
    }
  };

  public stickyLinkConfig: StickyConfig = new StickyConfig([
    new StickyLinkGroup(
      [
        new StickyLink('Home', '/buy', true, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Initials', '/initials-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Dateless', '/dateless-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('Build-Your-Own', '/byo-search', false, StickyLinkType.OUT_OF_PAGE),
        new StickyLink('25 Reg', '/new-release/25', false, StickyLinkType.OUT_OF_PAGE, '/new-release')
      ],
    ),
    new StickyLinkGroup([
      new StickyLink('Ultimate Names', '/names', false, StickyLinkType.OUT_OF_PAGE),
      new StickyLink('Number 1\'s', '/number-1', false, StickyLinkType.OUT_OF_PAGE),
      new StickyLink('Under £200', '/under-200', false, StickyLinkType.OUT_OF_PAGE),
    ]),
    // new StickyLinkGroup([
    //   new StickyLink('Sell', '/sell', false, StickyLinkType.OUT_OF_PAGE)
    // ]),
    new StickyLinkGroup([
      new StickyLink('Compare Prices', '/compare', false, StickyLinkType.OUT_OF_PAGE)
    ]), new StickyLinkGroup([
      new StickyLink('Plate Viewer', '/plate-viewer', false, StickyLinkType.OUT_OF_PAGE)
    ]),
    // new StickyLinkGroup([
    //   new StickyLink('Blogs', '/blogs', false, StickyLinkType.OUT_OF_PAGE)
    // ])
  ],
    new EventEmitter<void>(),
    this.config
  );

  constructor() { }

  ngOnInit(): void {
    this.stickyLinkConfig = { ...this.stickyLinkConfig, metaData: this.defaultConfig === true ? DEFAULT_STICKY_CONFIG : this.config };
    this.SetActivePage();
  }

  private SetActivePage(): void {
    const path = window.location.pathname;
    this.stickyLinkConfig.groups = this.stickyLinkConfig.groups.map(g => {
      g.links = g.links.map(l => {
        l.active = path.startsWith(l.routeMatch);
        if (l.anchor === '/buy' && (path.startsWith('/landing') || path === '/')) l.active = true;
        return l;
      });
      return g;
    })
  }

}
