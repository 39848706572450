<div style="position: relative">
  <!-- SCROLL TO POS -->
  <div #cont style="position: absolute; top: -80px"></div>

  <!-- VIEW TABLE -->
  <div class="results-container" *ngIf="shouldShow()">
    <div class="d-flex align-items-center justify-content-between py-2">
      <!-- HEADER TITLE -->
      <div class="info-container" *ngIf="!searching">
        <!-- NG CONTENT HEADER -->
        <ng-container *ngIf="customHeaderContent; else GenHeader">
          <ng-template [ngTemplateOutlet]="customHeader"></ng-template>
        </ng-container>

        <!-- PASSED TEXT HEADER -->
        <ng-template #GenHeader>
          <ng-container *ngIf="headerText != '' && headerText != null; else autoHeader">
            <p *ngIf="!showCount" class="fs-md-22 fs-mb-18 lh-sm text-dark">{{ headerText }}</p>
            <p *ngIf="showCount" class="fs-md-22 fs-mb-18 lh-sm text-dark">{{ headerText }} ({{ viewResults.length |
              number }})</p>
          </ng-container>
        </ng-template>

        <!-- BUILT HEADER -->
        <ng-template #autoHeader>
          <ng-container *ngIf="viewResults; else noResults">
            <p class="fs-md-22 fs-mb-18 lh-sm text-dark">
              {{ searchType }}
              <span class="fs-md-22 fs-mb-18 lh-sm text-dark fw-bold">'{{ searchTitle | uppercase }}'</span>
              {{ viewResults.length != 1 ? pluralResultType : resultType }}
              ({{ viewResults.length | number }})
            </p>
          </ng-container>

          <!-- NO RESULTS HEADER -->
          <ng-template #noResults>
            <p class="fs-md-22 fs-mb-18 lh-sm text-dark">
              {{ searchType }}
              <span class="fs-md-22 fs-mb-18 lh-sm text-dark fw-bold">'{{ searchTitle | uppercase }}'</span>
              {{ viewResults === undefined ? pluralResultType : viewResults.length != 1 ? pluralResultType : resultType
              }}
              (0)
            </p>
          </ng-template>
        </ng-template>
      </div>

      <!-- SEARCHING HEADER -->
      <div class="info-container" *ngIf="searching">
        <p class="fs-md-22 fs-mb-18 lh-sm text-dark">Searching...</p>
      </div>

      <!-- HEADER FLEX -->
      <div class="flex-space"></div>

      <!-- HEADER ACTIONS -->
      <!-- <div *ngIf="showFilter" class="badge" [matMenuTriggerFor]="filterMenu">
        <p>Filter</p>
      </div>
      <div *ngIf="showSort" class="badge" [matMenuTriggerFor]="sortMenu">
        <p>Sort</p>
      </div>
      <div *ngIf="showToggle" class="badge no-border no-hover">
        <mat-slide-toggle (change)="filterResults()" [(ngModel)]="showFullSeries">Show Full Series</mat-slide-toggle>
      </div> -->
      <!-- HEADER ACTION END -->
    </div>

    <div class="results-table border-top border-dark">
      <table>
        <ng-container *ngIf="searching; else realTable">
          <!-- SKELETON LOADING -->
          <ng-container *ngFor="let i of skeletonItems">
            <pla-search-result-row [loading]="true"></pla-search-result-row>
          </ng-container>
        </ng-container>
        <!-- ACTUAL RESULTS -->
        <ng-template #realTable>
          <ng-container *ngIf="displayResults && displayResults.length > 0; else noResults">
            <ng-container *ngFor="let plate of displayResults; let last = last">
              <ng-container *ngIf="resultRowType == standardRowType; else termResultRow">
                <pla-search-result-row [forcedTerm]="forcedTerm" [canRank]="canRank" [plate]="plate" [lastRow]="last" [showRating]="showRatings"
                  [allowShowMore]="allowShowMore" [whitePlate]="whitePlate" [metaText]="metaText"></pla-search-result-row>
              </ng-container>

              <ng-template #termResultRow>
                <pla-term-list-result-row [plate]="plate" [lastRow]="last" [customButton]="customButton"
                  [term]="plate.term" [allowShowMore]="allowShowMore" [showTerm]="showTerm"
                  [whitePlate]="whitePlate"></pla-term-list-result-row>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #noResults>
            <h2 style="text-align: center; margin: 20px auto 0">No Results</h2>
          </ng-template>
        </ng-template>
      </table>
      <div class="table-footer">
        <!-- NG CONTENT HEADER -->
        <div>
          <ng-container *ngIf="customHeaderContent; else GenHeader">
            <ng-template *ngTemplateOutlet="customHeader"></ng-template>
          </ng-container>
        </div>

        <ng-container *ngIf="!searching && viewResults && showPagination">

          <div class="d-block w-100 mt-4">
            <!-- <div *ngIf="(paginationPageSize * (currentPageIndex + 1) >= viewResults.length)" class="w-100">
              <p class="d-block mx-auto text-center text-muted">End of Results.</p>
            </div> -->
            <!-- Showing ({{paginationPageSize * (currentPageIndex + 1)}} / {{viewResults.length}}) -->
            <div class="d-flex align-items-center justify-content-between gap-2 mb-3"
              *ngIf="(paginationPageSize * (currentPageIndex + 1) < viewResults.length)">
              <div class="w-100" style="height: 1px; background-color: #DEDEDE;"></div>
              <div (click)="ShowMore()" class="mx-auto d-block rounded-pill px-3 py-1 text-center"
                style="background-color: #4D818E; user-select: auto;pointer-events: all;cursor: pointer; min-width: 150px;">
                <p class="my-0 text-white lh-1">See More</p>
                <p class="my-0 text-white lh-sm">Showing {{paginationPageSize * (currentPageIndex + 1)}} /
                  {{viewResults.length}}</p>
              </div>
              <div class="w-100" style="height: 1px; background-color: #DEDEDE;"></div>
            </div>
          </div>

          <!-- <mat-paginator [length]="viewResults.length" [pageSize]="paginationPageSize" [hidePageSize]="true"
            (page)="paginate($event)">
          </mat-paginator> -->
        </ng-container>
      </div>
    </div>
  </div>

  <!-- FILTER MENU -->
  <mat-menu #filterMenu="matMenu">
    <div class="mat-menu-content" (click)="$event.stopPropagation()">
      <ng-container *ngFor="let filter of filters">
        <p class="filter-title">{{ filter.viewName }}</p>
        <div class="filter-group">
          <ng-container *ngFor="let filterOption of filter.options">
            <div>
              <mat-checkbox [(ngModel)]="filterOption.value">{{ filterOption.viewName }}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <button mat-menu-item color="primary" (click)="filterResults()">
        Apply Filter
      </button>
    </div>
  </mat-menu>

  <!-- SORT MENU -->
  <mat-menu #sortMenu="matMenu" class="matMenuContainer">
    <button mat-menu-item (click)="sortResults('relevant')">
      Most Relevant
    </button>
    <!-- <button mat-menu-item (click)="sortResults('least-relevant')">
    Least Relevant
  </button> -->
    <button mat-menu-item (click)="sortResults('price-ascending')">
      Price Low to High
    </button>
    <button mat-menu-item (click)="sortResults('price-descending')">
      Price High to Low
    </button>
  </mat-menu>
</div>