<div *ngIf="loading" class="mx-auto text-center">
    <pla-loader></pla-loader>
</div>
<div *ngIf="!loading">
    <div class="mt-4">
        <div class="d-md-flex justify-content-start align-items-start gap-3 d-block mb-2">
            <button [ngClass]="{ 'platex-button': metricType == 0, 'platex-border-button': metricType != 0 }"
                class="btn" (click)="setMetric(0)">Impressions (search results)</button>
            <button [ngClass]="{ 'platex-button': metricType == 1, 'platex-border-button': metricType != 1 }"
                class="btn" (click)="setMetric(1)">Compares</button>
            <button [ngClass]="{ 'platex-button': metricType == 2, 'platex-border-button': metricType != 2 }"
                class="btn" (click)="setMetric(2)">Platex Clicks</button>
            <button [ngClass]="{ 'platex-button': metricType == 3, 'platex-border-button': metricType != 3 }"
                class="btn" (click)="setMetric(3)">Affiliate Clicks</button>
        </div>
    </div>
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-6 col-md-3 ps-0">
                <label>From</label>
                <input matInput class="form-control" type="date" [(ngModel)]="dateFrom" (change)="dateChange()">
            </div>
            <div class="col-6 col-md-3 pe-0">
                <label>To</label>
                <input matInput class="form-control" type="date" [(ngModel)]="dateTo" (change)="dateChange()">
            </div>

            <div class="col-12 px-0 mt-2">
                <small class="text-muted d-block w-100 text-center text-md-start">The entire day "From" Is included,
                    while "To" is excluded</small>
            </div>
        </div>
    </div>

    <div class="mt-4 w-100 ms-0 me-auto" *ngIf="!loading && metrics.length > 0">
        <details class="accordion-item">
            <summary class="accordion-button">{{ metrics.length }} Results | ({{ totalMetricCount }} Combined Events)
            </summary>
            <div class="accordion-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th class="fw-bolder">ID</th>
                            <th class="fw-bolder">Registration</th>
                            <th class="fw-bolder">Count</th>
                            <th class="fw-bolder"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <ng-container *ngFor="let metric of metrics; let index = index">
                            <tr>
                                <td>{{index + 1}}</td>
                                <td>
                                    <pla-plate [openModal]="false" [rawPlate]="metric.registration"
                                        [customClass]="'flat shaded'" [plateSize]="'ns-s'" [smallPlate]="false"
                                        [mobileTiny]="true"></pla-plate>
                                </td>
                                <td>
                                    <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                                        <p class="text-center lh-sm fs-md-16 mb-0">
                                            {{metric.result_count}}</p>
                                    </div>
                                </td>
                                <td>
                                    <button mat-mini-fab (click)="openDetail(metric.registration)">
                                        <mat-icon>chevron_right</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </details>
    </div>
</div>