<!-- TODO CLOSE IF ROUTE IS MATCHED -->
<!-- [ngClass]="{
    hasSearchNav: false && isSearchPage(),
    noSearchNav: true || !isSearchPage()
  }" -->
<div class="position-absolute" style="z-index: 100000; top: -100% !important;">
  <a tabindex="0" href="http://www.platex.co.uk" alt="The UKs Number Plate Portal">Home</a>
  <a tabindex="1" href="http://www.platex.co.uk/compare" alt="Price Compare">Price Compare</a>
  <a tabindex="2" href="http://www.platex.co.uk/under-200" alt="Under £200">Under 200</a>
  <a tabindex="3" href="http://www.platex.co.uk/initials-search" alt="Initials Search">Initials Search</a>
  <a tabindex="4" href="http://www.platex.co.uk/dateless-search" alt="Dateless Search">Dateless Search</a>
  <a tabindex="5" href="http://www.platex.co.uk/byo-search" alt="Build Your Own Search">Build Your Own Search</a>
  <a tabindex="6" href="http://www.platex.co.uk/25-release" alt="25 Release">25 Release</a>
  <a tabindex="7" href="http://www.platex.co.uk/names" alt="Names Search">Names Search</a>
  <a tabindex="8" href="http://www.platex.co.uk/number-1" alt="Number 1s">Number 1s</a>
  <a tabindex="9" href="http://www.platex.co.uk/plate-viewer" alt="Plate Viewer">Plate Viewer</a>
  <a tabindex="10" href="http://www.platex.co.uk/blogs" alt="Blogs">Blogs</a>
</div>
<div class="noSearchNav">
  <mat-drawer-container class="main-page-container overflow-visible" [ngClass]="{ noScroll: isFullScreenPage() }">
    <mat-drawer #navDrawer mode="over" position="start"
      style="width: 100vw; position: fixed; background-color: rgba(0, 0, 0, 0)" class="MOBILE-NAV-DRAWER"
      [autoFocus]="false">
      <div class="flex-container mobile-nav-header">
        <div class="logo">
          <a [routerLink]="'/landing'">
            <pla-platex-direct [colour]="'white'"></pla-platex-direct>
          </a>
        </div>
        <div class="flex-space"></div>
        <a class="nav-item" (click)="navDrawer.close()">
          <mat-icon data-nosnippet>close</mat-icon>
        </a>
      </div>
      <!-- MOBILE NAVIGATION -->
      <div class="navigation px-3">
        <!-- NON ICON OPTION LOOP -->
        <ng-container *ngFor="let navOption of navigation">
          <ng-container *ngIf="navOption.showOnMobile && !navOption.icon">
            <ng-container *ngIf="navOption.menu; else noMenu">

              <!--  -->
              <div class="item text-start" [routerLink]="navOption.route" (click)="closeNav()">
                <a class="ms-0 d-block text-start text-decoration-none">{{ navOption?.viewRoute
                  }}</a>
              </div>

              <!-- SUB MENU -->
              <div class="sub-menu-container">
                <ng-container *ngFor="let navMenuItem of navOption.menu">
                  <!-- dont show sub sub menus, false -> true to change -->
                  <ng-container *ngIf="false && navMenuItem.subMenu; else noSubMenu">
                    <div class="sub-item">
                      <div class="d-flex align-items-center justify-content-start">
                        <a class="text-white d-block text-start ms-0 text-decoration-none">{{ navMenuItem?.viewRoute
                          }}</a>
                        <mat-icon class="large text-white" data-nosnippet>chevron_right</mat-icon>
                      </div>
                    </div>
                    <div class="sub-sub-item" *ngFor="let navMenuSubItem of navMenuItem.subMenu"
                      (click)="navMenuSubItem.action()">
                      <div class="d-flex align-items-center justify-content-start">
                        <a class="text-white d-block text-start ms-0 text-decoration-none">{{ navMenuSubItem?.viewRoute
                          }}</a>
                        <mat-icon class="large text-white" data-nosnippet>chevron_right</mat-icon>
                      </div>
                    </div>
                  </ng-container>

                  <!-- NO SUB MENU -->
                  <ng-template #noSubMenu>
                    <div class="sub-item" (click)="navMenuItem.action()">
                      <div class="d-flex align-items-center justify-content-start">
                        <a class="text-white d-block text-start ms-0 text-decoration-none">{{ navMenuItem?.viewRoute
                          }}</a>
                        <mat-icon class="large text-white" data-nosnippet>chevron_right</mat-icon>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </ng-container>

            <!-- NO MAIN DROPDOWN MENU -->
            <ng-template #noMenu>
              <ng-container *ngIf="navOption != null">
                <div class="item">
                  <div class="d-flex align-items-center justify-content-start">
                    <a class="text-white d-flex align-items-center justify-content-start text-start ms-0 text-decoration-none"
                      [routerLink]="navOption.route" (click)="closeNav()">
                      {{ navOption?.viewRoute }}
                    </a>
                    <mat-icon class="m-large text-white" data-nosnippet>chevron_right</mat-icon>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- ICON OPTION LOOPS -->
        <ng-container *ngFor="let navOption of navigation">
          <ng-container *ngIf="navOption.showOnMobile && navOption.icon">
            <ng-container *ngIf="navOption.menu; else noMenu">
              <div class="item">
                <a class="text-white d-block text-start ms-0 text-decoration-none" [matMenuTriggerFor]="menu">
                  <!-- {{ navOption?.viewRoute }} -->
                  <mat-icon data-nosnippet class="material-icons-outlined">{{
                    navOption.iconName
                    }}</mat-icon>
                  <span *ngIf="navOption?.viewRoute" class="text-white icon-button-text">{{
                    navOption?.viewRoute
                    }}</span>
                </a>
              </div>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let navMenuItem of navOption.menu">
                  <button class="text-dark" mat-menu-item (click)="navMenuItem.action()">
                    {{ navMenuItem?.viewRoute }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
            <ng-template #noMenu>
              <div class="item">
                <a class="text-white" [routerLink]="navOption.route" (click)="closeNav()">
                  <!-- {{ navOption?.viewRoute }} -->
                  <mat-icon data-nosnippet class="text-white material-icons-outlined">{{
                    navOption.iconName
                    }}</mat-icon>
                  <span *ngIf="navOption?.viewRoute" class="text-white icon-button-text">{{
                    navOption?.viewRoute
                    }}</span>
                </a>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </mat-drawer>

    <mat-drawer-content [ngClass]="{ noScroll: isFullScreenPage() }" class="overflow-visible">
      <!-- <pla-top-bar></pla-top-bar> -->
      <div class="site-header-container fixed">
        <div class="site-header">
          <pla-header (navigationToggle)="navDrawer.open()" [navigation]="navigation"></pla-header>
        </div>
        <div class="spacer full"></div>
      </div>
      <div class="not_page">
        <pla-search-variant-bar *ngIf="false && isSearchPage()" [searchOptions]="options"></pla-search-variant-bar>

        <!-- <pla-feedback-modal *ngIf="notPlateDetailPage"></pla-feedback-modal> -->
        <div class="{{ isLandingPage() ? '' : '' }}">
          <router-outlet></router-outlet>
        </div>
      </div>
      <pla-footer *ngIf="!isFullScreenPage()"></pla-footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>