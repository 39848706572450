import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import TrackingMetric from 'src/app/models/trackingMetric';
import { DataPoint } from '../chart/chart.component';
import { ListingService } from 'src/app/services/listing-service';
import { ActivatedRoute, Router } from '@angular/router';

const HOUR = 3600000;

@Component({
  selector: 'pla-registration-metrics',
  templateUrl: './registration-metrics.component.html',
  styleUrls: ['./registration-metrics.component.scss']
})
export class RegistrationMetricsComponent implements OnInit {

  @Input() public search: EventEmitter<string> = new EventEmitter<string>();
  public loading: boolean = false;
  public registration: string;
  private allMetrics: TrackingMetric[] = [];
  public trackingData: TrackingMetric[] = [];
  public impressionData: DataPoint[] = [];
  public clickData: DataPoint[] = [];
  public affiliateData: DataPoint[] = [];

  public days: number = 7;

  constructor(private listingService: ListingService, private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(_p => {
      this.days = _p['days'];
      if (this.days == null) this.days = 7;
    });
  }

  ngOnInit(): void {
    if (this.registration != undefined && this.registration != '') this.fetchMetrics();
    this.search.subscribe((_) => { this.registration = _; this.fetchMetrics(); });
  }

  public setDays(_days: number): void {
    this.days = _days;
    this.loading = true;

    setTimeout(() => {
      this.sortMetrics(this.allMetrics);
      this.loading = false;
    }, 500);

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { registration: this.registration.toUpperCase(), days: this.days },
        queryParamsHandling: 'merge',
      }
    );
  }

  public orderedTrackingData(): TrackingMetric[] {
    return this.trackingData.sort((a, b) => b.day.getTime() - a.day.getTime());
  }

  public afClick(links: string[]): string[] {
    const formattedLinks: any = {};
    links.forEach((l) => {
      if (Object.keys(formattedLinks).indexOf(l) > -1) {
        formattedLinks[l] = formattedLinks[l] + 1;
      } else {
        formattedLinks[l] = 1;
      }
    });
    return Object.keys(formattedLinks).map((link) => {
      const rawLink = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img.exec(link)
      return `(${formattedLinks[link]} clicks)  ${rawLink[1]}`;
    });
  }


  private fetchMetrics(): void {
    this.loading = true;
    this.listingService.GetListingMetrics(this.registration).subscribe(
      (_: TrackingMetric[]) => { this.allMetrics = _; this.sortMetrics(this.allMetrics); this.loading = false; },
      () => { this.loading = false; }
    );
  }

  private sortMetrics(metrics: TrackingMetric[]): void {
    const aggregateData = this.PadAggregate(metrics, this.days);
    this.trackingData = aggregateData;
    this.impressionData = this.extractMetricData(
      aggregateData,
      'impressionCount'
    );
    this.clickData = this.extractMetricData(aggregateData, 'clickCount');
    this.affiliateData = this.extractMetricData(aggregateData, 'affiliateCount');
  }

  private extractMetricData(
    metrics: TrackingMetric[],
    key: string
  ): DataPoint[] {
    const m = metrics.map((m: TrackingMetric) => {
      return new DataPoint(m.day, m[key]);
    });
    return m;
  }

  private PadAggregate(
    metrics: TrackingMetric[],
    days: number = 7
  ): TrackingMetric[] {
    var aggregateData: any[] = [];

    const span = HOUR * 24;
    const totalSpan = span * days;
    const endDate = new Date(Date.now());
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() + 1);
    const startDate = new Date(endDate.getTime() - totalSpan);

    for (let i = startDate.getTime(); i < endDate.getTime(); i += span) {
      const dayMetric = metrics.filter((m) => {
        const tempDay = new Date(m.day);
        tempDay.setHours(0, 0, 0, 0);
        return tempDay.getTime() == i;
      });
      if (dayMetric.length == 0) {
        var _date = new Date(i);
        _date.setHours(0, 0, 0, 0);
        aggregateData.push(new TrackingMetric().AddDate(_date));
      } else {
        const day = dayMetric[0];
        day.impressionCount += day.comparedCount; // compares wont show, just count as impressions
        aggregateData.push(new TrackingMetric().Build(day).AddRegistration(day.registration));
      }
    }

    return aggregateData;
  }

}
