import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin-service';

@Component({
  selector: 'pla-metric-portal',
  templateUrl: './metric-portal.component.html',
  styleUrls: ['./metric-portal.component.scss']
})
export class MetricPortalComponent implements OnInit {

  // # track_type
  // #impression = 0
  // #compared = 1
  // #click = 2
  // #affiliate = 3
  public metricType: number;
  public dateFrom: string;
  public dateTo: string;
  public loading: boolean;
  public totalMetricCount: number = 0;
  public metrics: { registration: string, track_type: number, result_count: number }[] = [];

  constructor(private adminService: AdminService, private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(_p => {
      this.metricType = _p['metricType'];
      this.dateFrom = _p['dateFrom'];
      this.dateTo = _p['dateTo'];

      if (this.metricType == null) this.metricType = 0;
      if (this.dateFrom == null) this.dateFrom = new Date().toString();
      if (this.dateTo == null) this.dateTo = new Date(new Date().getTime() + 86400000).toString();
      this.fetchMetrics();
    });
  }

  ngOnInit(): void {
  }

  public dateChange(): void {
    this.setMetric(this.metricType);
  }

  private fetchMetrics(): void {
    this.loading = true;
    this.adminService.getMetricsForPeriod(this.metricType, new Date(this.dateFrom), new Date(this.dateTo), (_: { registration: string, track_type: number, result_count: number }[]) => {
      this.metrics = _;
      this.loading = false;

      this.totalMetricCount = 0;
      _.forEach(__ => { this.totalMetricCount += __.result_count });
    })
  }

  public openDetail(registration: string): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { metricType: this.metricType, dateFrom: new Date(this.dateFrom).toISOString().substr(0, 10), dateTo: new Date(this.dateTo).toISOString().substr(0, 10), registration: registration, days: 7 },
        queryParamsHandling: 'merge',
      }
    );
  }

  public setMetric(metric: number): void {
    this.loading = true;
    this.metricType = metric;

    setTimeout(() => {
      this.fetchMetrics();
    }, 500)

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { metricType: this.metricType, dateFrom: new Date(this.dateFrom).toISOString().substr(0, 10), dateTo: new Date(this.dateTo).toISOString().substr(0, 10) },
        queryParamsHandling: 'merge',
      }
    );
  }

}
