import { PriceBreakdown } from './priceBreakdown';

export class RawSoldPlate {
  id: number
  registration: string
  sold_price: string
  style: string
  sold_date: string
  sale_type: number
  prefix: string
  numbers: string
  letters: string
  seller: string
  active_point: boolean
  requires_approval: boolean
}

export class AddedFee {
  public name: string;
  public viewName: string;
  public description: string;
  public price: number;
}

export class Registration {
  public id: number;
  public index: number;
  public search: string;
  public registration: string;
  public formattedRegistration: string;
  public alphabet: string;
  public prefix: string;
  public term: string;
  public suffix: string;
  public score: number;
  public percentage: number;
  public startingFrom: number;
  public max_price: number;
  public stars: number;
  public fullStars: string[];
  public halfStar: boolean;
  public emptyStars: string[];
  public style: string;
  public price: number;
  public plateOwner: number;
  public plate_owner: number;
  public finance: boolean;
  public financeDetails: any;
  public priceBreakdown: PriceBreakdown;
  public available: boolean;
  public favourite: boolean = false;
  public notify: boolean = false;
  public registered: string = null;
  public likes: number = 0;
  public dislikes: number = 0;
  public seller: string;
  public meta_data: any = {};
  public userRating: boolean | undefined;
  public date: Date;
  public date_added: string;

  // Only needed to new up a plate?
  constructor(
    public _id: number,
    public _registration: string,
    public _formattedRegistration: string,
    public _price: number = 0
  ) {
    this.id = _id;
    this.registration = _registration;
    this.formattedRegistration = _formattedRegistration;
    this.price = _price;
  }
}
