import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchReqResponse } from 'src/app/services/search-service';

@Component({
  selector: 'pla-admin-recently-added',
  templateUrl: './admin-recently-added.component.html',
  styleUrls: ['./admin-recently-added.component.scss']
})
export class AdminRecentlyAddedComponent implements OnInit {

  public loading: boolean = true;
  public tableRegistrations: SearchReqResponse;

  constructor(private adminService: AdminService, private registrationService: RegistrationService) {
    this.adminService.GetRecentlyAdded((res) => {
      var registrations = this.registrationService.formatRegistrations(res);
      this.tableRegistrations = new SearchReqResponse('recently-added', registrations.sort((a, b) => b.date.getTime() - a.date.getTime()));
      this.loading = false;
    });
  }

  ngOnInit(): void {
  }

}
